/* Fonts */
/* @font-face {
  font-display: swap;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  src: url("../../public/fonts/SourceSansPro-Regular.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  src: url("../../public/fonts/SourceSansPro-SemiBold.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  src: url("../../public/fonts/SourceSansPro-Bold.woff") format("woff");
} */

@font-face {
  font-family: MerriweatherBlack;
  src: url("./fonts/Merriweather-Black.ttf") format("truetype");
}

@font-face {
  font-family: MerriweatherBold;
  src: url("./fonts/Merriweather-Bold.ttf") format("truetype");
}

@font-face {
  font-family: MerriweatherRegular;
  src: url("./fonts/Merriweather-Regular.ttf") format("truetype");
}

@font-face {
  font-family: MerriweatherLight;
  src: url("./fonts/Merriweather-Light.ttf") format("truetype");
}

@font-face {
  font-family: LatoBlack;
  src: url("./fonts/Lato-Black.ttf") format("truetype");
}

@font-face {
  font-family: LatoBold;
  src: url("./fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: LatoRegular;
  src: url("./fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: LatoLight;
  src: url("./fonts/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: RobotoRegular;
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: RobotoMedium;
  src: url("./fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: RobotoBold;
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: InterRegular;
  src: url("./fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: InterMedium;
  src: url("./fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: InterSemiBold;
  src: url("./fonts/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: NoticiaText;
  font-weight: 400;
  src: url("./fonts/NoticiaText-Regular.ttf") format("truetype");
}

@font-face {
  font-family: NoticiaText;
  font-weight: 700;
  src: url("./fonts/NoticiaText-Bold.ttf") format("truetype");
}
@font-face {
  font-family: Aeonik;
  src: url("./fonts/Aeonik-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Aeonik;
  src: url("./fonts/Aeonik-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Aeonik;
  src: url("./fonts/Aeonik-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Aeonik;
  src: url("./fonts/Aeonik-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Aeonik;
  src: url("./fonts/Aeonik-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

html,
body,
p,
button,
input,
select,
textarea,
pre,
code,
kbd,
tt,
var,
samp,
.main-navigation a,
search-input-holder .search-field,
.widget .widgettitle,
.widget .widget-title {
  font-family: "Source Sans Pro";
  font-weight: 400;
}
html,
body {
  margin: 0px;
  padding: 0px;
}
p,
ul li,
ol li {
  font-size: 18px;
}
ul,
ol {
  padding-left: 20px;
}

/* Header Styles */
.header-wrapper {
  background-color: #101661;
}
.header {
  max-width: 1110px;
  padding: 20px 0 20px 0;
  height: 80px;
  margin: 0 auto;
}
.logo {
  display: inline-block;
}
.logo .logo-title {
  display: inline-flex;
  flex-direction: column;
  margin-left: 12px;
  text-align: center;
}
.logo .logo-title .logo-text {
  font-size: 28px;
  line-height: 28px;
  font-weight: 600;
}

.logo .logo-title .logo-subtext {
  font-size: 14px;
  line-height: 18px;
}

/* Content Styles */
.content-wrapper {
  width: 100%;
  height: 100%;
  min-height: 540px;
  display: block;
  padding-bottom: 30px;
  /* position: relative; */
}
/* .content-wrapper::after {
  content: "";
  background: url(../img/usa-flag-bg.png) top right no-repeat;
  opacity: 0.2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  background-color: #fcfdfe;
} */
.content {
  max-width: 100%;
  /* max-width: 1110px; */
  margin: 0 auto;
  padding: 70px 0 100px 0;
}
.content h1 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
  text-align: right;
  max-width: 1110px;
  margin: 0 auto !important;
  margin-bottom: 20px !important;
}
.content .box {
  border: 1px solid #9a9a9a;
  padding: 60px 0px 60px 0px;
  max-width: 860px;
  margin: 0 auto;
  margin-bottom: 70px;
}
.back-btn {
  padding: 15px 20px;
  background: #101661 !important;
  border: 0 none;
  cursor: pointer;
  border-radius: 2px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 6px;
}
/* Footer Styles */
.footer-wrapper {
  background-color: #101661;
}
.footer {
  /* max-width: 1200px; */
  max-width: 94%;
  padding: 60px 0 40px 0;
  margin: 0 auto;
}
.copyright {
  width: 25%;
  display: inline-block;
}
.copyright p {
  font-size: 20px;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
}
.footer .links {
  display: inline-block;
  text-align: right;
  width: 74%;
}
.footer .links li {
  display: inline;
  color: #fff;
  margin-right: 15px;
  font-size: 20px;
}
.footer .links li::after {
  content: "|";
  margin-left: 15px;
}
.footer .links li:last-child {
  margin-right: 0px;
}
.footer .links li:last-child::after {
  content: "";
  margin-left: 0px;
}
.footer .links li a,
.footer .links li a:link,
.menu li a:visited {
  display: inline;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
}
.footer hr {
  margin: 30px 0 30px 0;
  opacity: 0.3;
}
.footer .text h5 {
  font-size: 18px;
  color: #fff;
  margin-bottom: 15px;
}
.footer .text p {
  font-size: 16px;
  color: #fff;
  opacity: 0.5;
}

.w-40 {
  width: 40% !important;
}
.w-35 {
  width: 35% !important;
}

.form-group:after {
  content: " ";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
  padding: 0px 10px !important;
  cursor: pointer !important;
}

/* Mobile view */
@media (max-width: 1280px) {
  .footer {
    padding: 60px 20px 40px 20px;
  }
  .header {
    padding: 20px 20px 20px 20px;
  }
  .content {
    padding: 70px 20px 70px 20px;
  }
  .footer .text p {
    font-size: 10px;
  }
  .footer .text h5 {
    font-size: 12px;
  }
  .content .box {
    margin-left: 20px;
    margin-right: 20px;
  }
  .content h1 {
    margin-right: 20px !important;
  }

  .footer .links li {
    display: block;
  }
  .footer .links li::after {
    display: none;
  }
}
/*iPad*/
@media (max-width: 768px) and (min-width: 442px) {
  .content .box {
    padding: 20px;
  }
}
@media (max-width: 441px) {
  .content .box {
    padding: 20px 0 !important;
  }
  .copyright {
    width: 100%;
    display: block;
  }
  .footer .links {
    display: block;
    text-align: left;
    width: 100%;
  }
  .logo {
    display: inline;
  }
  .logo img {
    max-width: 95%;
  }

  .logo .logo-title .logo-text {
    font-size: 22px;
    line-height: 22px;
  }

  .logo .logo-title .logo-subtext {
    font-size: 12px;
    line-height: 16px;
  }
}
@media (max-width: 640px) {
  .header {
    height: 69px;
    padding: 10px 20px 20px 20px;
  }
}
